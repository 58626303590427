<template>
  <div class="icon">
    <b-img :src="`${publicPath}img/wysiwyg-icons/${icon}.svg`"></b-img>
  </div>
</template>

<script>
  export default {
    props: {
      icon: {
        type: String,
        default: 'bold'
      }
    },
    data () {
      return {
        publicPath: process.env.BASE_URL
      }
    }
  }
</script>

<style lang="scss" scoped>
  .icon {

    img {
      display: inline-block;
      width: 12px;
      height: auto;
    }
  }
</style>
