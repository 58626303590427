<template>
  <b-container>
    <div v-if="!dataReady" style="font-size: 30px; text-align: center; margin-top: 60px;">
      <i class="far fa-circle-notch fa-spin"></i> {{ trans('global.loading') }}...
    </div>
    <div v-else>
      <b-row class="mt-5">
        <b-col>
          <b-form @submit.prevent>
            <vary-card title="Produktdetaljer" description="Produktetinformasjon" class="margin-bottom-30">
              <b-row>
                <b-col sm="12" md="6">
                  <b-form-group id="project_image_group" label="Produktbilde" label-for="project_image">
                    <div :class="(!productImageStyle)? 'noimg' : ''" class="vary-image-container" :style="productImageStyle">
                      <div v-if="!productImageStyle" class="text">
                        <i class="fas fa-file-image" style="font-size: 40px;"></i>
                        <span>{{ trans('projects.image_description') }}</span>
                      </div>
                    </div>
                    <div v-if="uploadStatus === 1" class="vary-image-progress">
                      <b-progress :value="Math.round(progress)" variant="primary" striped :animated="true" show-progress></b-progress>
                    </div>
                    <b-form-file
                      id="project_image"
                      v-model="form.image"
                      :state="imageFileStatus"
                      :placeholder="trans('projects.image_placeholder')"
                      class="margin-top-16"
                      accept="image/jpeg, image/png"
                      @change="upload"
                    >
                    </b-form-file>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="product_name_group"
                    :label="trans('prospects.designer.form_add_room_new_product.name')"
                    label-for="product_name"
                  >
                    <b-form-input
                      id="product_name"
                      v-model="$v.form.name.$model"
                      name="product_name"
                      class="form-control-lg"
                      :state="formErrors.name"
                      type="text"
                      :placeholder="trans('prospects.designer.form_add_room_new_product.name_description')"
                      aria-describedby="name-msg"
                      trim
                    ></b-form-input>
                    <b-form-invalid-feedback id="name-msg">
                      <span
                        v-if="!$v.form.name.minlength"
                      >
                        {{ trans('prospects.designer.form_add_room_new_product.name_min_length') }}
                      </span>
                      <span
                        v-if="!$v.form.name.required"
                      >
                        {{ trans('prospects.designer.form_add_room_new_product.name_required') }}
                      </span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="product_price_group"
                    label="Pris"
                    label-for="product_price"
                  >
                    <b-form-input
                      id="product_price"
                      v-model="form.price"
                      name="product_price"
                      class="form-control-lg"
                      :state="formErrors.price"
                      type="text"
                      placeholder="Veiledende produktpris"
                      aria-describedby="price-msg"
                      trim
                    ></b-form-input>
                    <!-- <b-form-invalid-feedback id="price-msg">-->
                    <!--   <span-->
                    <!--     v-if="!$v.form.price.minlength"-->
                    <!--   >-->
                    <!--     {{ trans('prospects.designer.form_add_room_new_product.name_min_length') }}-->
                    <!--   </span>-->
                    <!--   <span-->
                    <!--     v-if="!$v.form.name.required"-->
                    <!--   >-->
                    <!--     {{ trans('prospects.designer.form_add_room_new_product.name_required') }}-->
                    <!--   </span>-->
                    <!-- </b-form-invalid-feedback>-->
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="product_shorttxt_group"
                    label="Produktbeskrivelse"
                    label-for="product_shorttxt"
                    class="vary-wysiwyg"
                  >
                    <editor-menu-bubble
                      v-slot="{ commands, isActive, getMarkAttrs, menu }"
                      class="menububble"
                      :editor="editor"
                      @hide="hideLinkMenu"
                    >
                      <div
                        class="menububble"
                        :class="{ 'is-active': menu.isActive }"
                        :style="`left: ${menu.left + 80}px; bottom: ${menu.bottom}px;`"
                      >
                        <form
                          v-if="linkMenuIsActive"
                          class="menububble__form"
                          @submit.prevent="setLinkUrl(commands.link, linkUrl)"
                        >
                          <input
                            ref="linkInput"
                            v-model="linkUrl"
                            class="menububble__input"
                            type="text"
                            placeholder="https://"
                            @keydown.esc="hideLinkMenu"
                          />
                          <button
                            class="menububble__button"
                            type="button"
                            @click="setLinkUrl(commands.link, null)"
                          >
                            <Icon icon="remove" />
                          </button>
                        </form>

                        <template v-else>
                          <button
                            class="menububble__button"
                            :class="{ 'is-active': isActive.link() }"
                            @click="showLinkMenu(getMarkAttrs('link'))"
                          >
                            <span>{{ isActive.link() ? 'Update Link' : 'Add Link' }}</span>
                            <Icon icon="link" />
                          </button>
                        </template>
                      </div>
                    </editor-menu-bubble>

                    <editor-menu-bar v-slot="{ commands, isActive }" class="menubar" :editor="editor">
                      <b-button-group>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                          @click="commands.heading({ level: 1 })"
                        >
                          H1
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                          @click="commands.heading({ level: 2 })"
                        >
                          H2
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                          @click="commands.heading({ level: 3 })"
                        >
                          H3
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.bold() }"
                          @click="commands.bold"
                        >
                          <Icon icon="bold"></Icon>
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.italic() }"
                          @click="commands.italic"
                        >
                          <Icon icon="italic"></Icon>
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.strike() }"
                          @click="commands.strike"
                        >
                          <Icon icon="strike"></Icon>
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.underline() }"
                          @click="commands.underline"
                        >
                          <Icon icon="underline"></Icon>
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.bullet_list() }"
                          @click="commands.bullet_list"
                        >
                          <Icon icon="ul"></Icon>
                        </button>
                        <button
                          class="menubar__button"
                          :class="{ 'is-active': isActive.ordered_list() }"
                          @click="commands.ordered_list"
                        >
                          <Icon icon="ol"></Icon>
                        </button>
                      </b-button-group>
                    </editor-menu-bar>
                    <editor-content ref="editor" class="editor" :editor="editor" />
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="product_longtxt_html_group" label="HTML versjon" label-for="product_longtxt">
                    <b-form-textarea
                      id="product_longtxt"
                      v-model="productDescHtml"
                      name="product_longtxt"
                      class="form-control-lg"
                      placeholder="Skriv inn ekstra informasjon til produktet"
                      rows="6"
                      max-rows="12"
                    ></b-form-textarea>
                  </b-form-group>
                  <b-btn
                    @click="updateProductDescHtml"
                  >
                    Send HTML til beskrivelsefeltet
                  </b-btn>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="product_longtxt_group" label="Ekstra informasjon" label-for="product_longtxt">
                    <b-form-textarea
                      id="product_longtxt"
                      v-model="form.longtxt"
                      name="product_longtxt"
                      class="form-control-lg"
                      placeholder="Skriv inn ekstra informasjon til produktet"
                      rows="6"
                      max-rows="12"
                    ></b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-button
                    v-if="submitStatus === 1"
                    ref="submitbtn"
                    variant="primary"
                    size="md"
                    class="padding-left-60 padding-right-60"
                    disabled
                  >
                    <b-spinner variant="white"></b-spinner>
                  </b-button>
                  <b-button
                    v-else
                    ref="submitbtn"
                    variant="primary"
                    size="md"
                    class="padding-left-60 padding-right-60"
                    @click="updateProduct"
                  >
                    <span>{{ trans('global.save') }}</span>
                  </b-button>
                </b-col>
              </b-row>
            </vary-card>
          </b-form>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col>
          <vary-card
            title="Produktbilder"
            description="Produktbilder som blir vist under utvidet produktinfo"
          >
            <b-row>
              <b-col>
                <div class="carouselwrp">
                  <draggable
                    v-if="productImages"
                    v-model="productImages"
                    class="draggableimages"
                    :class="{ 'disabled': !enabled }"
                    ghost-class="imageghost"
                    :disabled="!enabled"
                    @end="updateImages"
                  >
                    <DraggableProductImage
                      v-for="image in productImages"
                      :key="image.id"
                      :image="image"
                      :progress="Math.round(imageUploadProgress[image.id])"
                      :product="product"
                    ></DraggableProductImage>
                  </draggable>
                  <div
                    v-else
                    class="alert alert-warning"
                  >
                    <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('prospects.landingpage.carousel_empty') }}
                  </div>
                  <div v-cloak class="uploadField" :class="{'dragactive': dragging}">
                    <i class="far fa-file-upload"></i>
                    <p>{{ trans('prospects.landingpage.drag_files_to_upload') }}</p>
                    <b-form-file
                      v-model="formFiles"
                      accept="image/jpeg, image/png, image/gif"
                      multiple
                      :disabled="uploadDisabled"
                      @input="addFiles"
                      @dragenter="dragging=true"
                      @dragend="dragging=false"
                      @dragleave="dragging=false"
                    ></b-form-file>
                  </div>
                </div>
              </b-col>
            </b-row>
          </vary-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <vary-card title="Produktbruk" :description="(usedInProspects.length || 'Ingen') + ' prosjekter bruker produktet'" class="margin-bottom-30">
            <b-row v-for="(prospect, pidx) in usedInProspects" :key="prospect.varycode + '-' + pidx" class="prodlist">
              <b-col>
                <div class="d-flex py-4">
                  <div
                    class="flex-shrink-0 symbol-50 symbol-lg-120 mr-4 rounded"
                    :style="{
                      backgroundImage: 'url(' + prospect.image + '?fit=crop&h=120)',
                      backgroundSize: 'cover'
                    }"
                  >
                  </div>
                  <div
                    class="flex-grow-1"
                  >
                    <b-link
                      :to="prospect.projectUrl"
                      class="mt-2 mb-0 font-size-large"
                    >
                      {{ prospect.title }}
                    </b-link>
                    <p v-if="prospect.projectName" class="text-muted">
                      {{ prospect.projectName }}
                    </p>
                    <div class="d-flex">
                      <span v-for="(room, index) in prospect.rooms" :key="index + 'room'" class="badge font-size-small badge-success mr-2 p-2" style="color: white; font-weight: normal;">
                        {{ room }}
                      </span>
                    </div>
                  </div>
                </div>
              </b-col>
            </b-row>
          </vary-card>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
  import { mapState } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { minLength, required } from 'vuelidate/lib/validators'
  import { Editor, EditorContent, EditorMenuBar, EditorMenuBubble } from 'tiptap'
  import {
    Heading,
    Blockquote,
    OrderedList,
    BulletList,
    ListItem,
    TodoItem,
    TodoList,
    Bold,
    Italic,
    Link,
    Strike,
    Underline
  } from 'tiptap-extensions'

  import Vue from 'vue'
  import * as objectPath from 'object-path'
  import draggable from 'vuedraggable'
  import DraggableProductImage from '@/components/Views/Products/DraggableProductImage'

  import Icon from '../../components/Core/WysiwygIcons'
  import VaryCard from '../../components/Core/Card'

  const fb = require('../../firebaseConfig')
  const varyConfig = require('../../varyConfig')

  export class CustomLink extends Link {
    get schema () {
      return {
        attrs: {
          href: {
            default: null
          },
          target: {
            default: null
          }
        },
        inclusive: false,
        parseDOM: [
          {
            tag: 'a[href]',
            getAttrs: dom => ({
              href: dom.getAttribute('href'),
              target: dom.getAttribute('target')
            })
          }
        ],
        toDOM: node => ['a', {
          ...node.attrs,
          target: '__blank',
          rel: 'noopener noreferrer nofollow'
        }, 0]
      }
    }
  }

  // const fb = require('../../firebaseConfig')

  export default {
    name: 'AllProducts',
    components: {
      'vary-card': VaryCard,
      EditorContent,
      EditorMenuBar,
      EditorMenuBubble,
      Icon,
      DraggableProductImage,
      draggable
    },
    mixins: [validationMixin],
    data () {
      return {
        submitStatus: 0,
        linkUrl: null,
        linkMenuIsActive: false,
        editor: null,
        productDescHtml: '',
        product: {},
        loading: true,
        progress: 0,
        uploadStatus: 0, // 0 = nothing, 1 = progress, 2 = complete
        imageFileStatus: null,
        form: {},
        productImages: this.formatImagesArray(objectPath.get(this, 'product.images', {})),
        enabled: true,
        imageUploadProgress: {},
        dragging: false,
        formFiles: [],
        uploadDisabled: false,
        nextImageKeys: {},
        uploadingImages: [],
        updateAllProspectsUsingThisProductBatchArray: [],
        updateAllProspectsUsingThisProductBatchIndex: 0,
        updateAllProspectsUsingThisProductBatchCounter: 0
      }
    },
    validations () {
      return {
        form: {
          name: {
            required,
            minlength: minLength(2)
          }
        }
      }
    },
    computed: {
      ...mapState({
        products: state => state.products.products,
        prospects: state => state.prospects.prospects,
        projects: state => state.projects.projects
      }),
      dataReady () {
        if (this.editor) this.editor.destroy() // Reset the editor
        this.init()
        return this.products !== undefined && this.products.length > 0 && this.product !== false
      },
      productName () {
        return this.product.name ?? 'Produkt'
      },
      productImageStyle () {
        const lastProjectImageKey = this.product.image !== undefined ? Object.keys(this.product.image).pop() : null
        return (this.product.image !== undefined) ? 'background-image: url("' + process.env.VUE_APP_IMAGE_CDN_URL + '/' + this.product.image[lastProjectImageKey].path + '/' + this.product.image[lastProjectImageKey].file + '")' : null
      },
      formErrors () {
        return {
          name: this.$v.form.name !== undefined && this.$v.form.name.$dirty && this.submitStatus === 1 ? !this.$v.form.name.$error : null
        }
      },
      usedInProspects () {
        const self = this
        const prospects = []
        self.prospects.forEach((prospect) => {
          let currentProspect = {}
          if ( // Check if the prospect has rooms
            prospect.rooms !== undefined &&
            typeof prospect.rooms === 'object' &&
            prospect.rooms !== null
          ) {
            Object.keys(prospect.rooms).map((roomKey) => {
              const products = prospect.rooms[roomKey].products
              if ( // Check if the room has products
                products !== undefined &&
                typeof products === 'object' &&
                products !== null
              ) {
                Object.keys(products).map((prodKey) => {
                  const prod = products[prodKey]
                  if (
                    prod !== undefined &&
                    typeof prod === 'object' &&
                    prod !== null
                  ) {
                    if (prod.parentId === self.product._id) {
                      // Create the prospect object if missing
                      if (Object.keys(currentProspect).length === 0) {
                        currentProspect = {
                          image: this.prospectImage(prospect),
                          title: prospect.title,
                          varycode: prospect.varycode,
                          rooms: []
                        }
                      }

                      const proj = self.projects.find(project => project._id === prospect.projectId)

                      if (proj) {
                        currentProspect.projectName = proj.name
                        currentProspect.projectSlug = proj.slug
                        currentProspect.projectUrl = '/projects/' + proj.slug + '/sales/' + prospect.slug
                      } else {
                        currentProspect.projectName = null
                        currentProspect.projectSlug = null
                        currentProspect.projectUrl = null
                      }

                      currentProspect.rooms.push(prospect.rooms[roomKey].customname !== undefined
                        ? prospect.rooms[roomKey].customname
                        : prospect.rooms[roomKey].category !== undefined
                          ? this.trans('global.' + varyConfig.roomCategories[prospect.rooms[roomKey].category])
                          : '(rom mangler navn)'
                      )
                    }
                  }
                })
              }
            })
          }

          if (Object.keys(currentProspect).length > 0) prospects.push(currentProspect)
        })

        return prospects
      }
    },
    watch: {
      product (val) {
        this.productImages = this.formatImagesArray(val.images)
      }
    },
    beforeMount () {
      this.updateBreadcrumb(
        [
          { text: this.trans('global.products'), to: '/products' },
          { text: 'Rediger', to: '#' },
          { text: this.productName, to: '#' }
        ]
      )
    },
    methods: {
      updateProductDescHtml () {
        this.editor.setContent(this.productDescHtml)
      },
      init () {
        this.product = this.products.find(product => product.varycode === this.$route.params.productvid) ?? false
        if (this.product) {
          this.form.name = this.product.name
          this.form.price = objectPath.get(this, 'product.price', null)
          this.form.shorttxt = this.product.shorttxt
          this.form.longtxt = this.product.longtxt
          this.form.image = null

          console.log('This FORM:', this.form)

          this.updateBreadcrumb(
            [
              { text: this.trans('global.products'), to: '/products' },
              { text: 'Rediger', to: '#' },
              { text: this.productName, to: '#' }
            ]
          )

          this.initializeEditor()
        }
      },
      productImage (product) {
        const imageKey = product.image !== undefined && Object.keys(product.image).length > 0 ? Object.keys(product.image)[Object.keys(product.image).length - 1] : false

        return imageKey ? process.env.VUE_APP_IMAGE_CDN_URL + '/' + product.image[imageKey].path + '/' + product.image[imageKey].file + '?fit=crop&w=100&h=100&mask=corners&corner-radius=5,5,5,5' : false
        // return this.products
      },
      async upload (e) {
        const self = this
        this.uploadStatus = 1
        this.form.image = e.target.files[0]

        let randomTxt = ''
        const length = 16
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        for (let i = 0; i < length; i++) {
          randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
        }

        if (this.form.image !== undefined) {
          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: this.form.image.type
          }

          const newFilename = this.product.slug + '_' + randomTxt + '.' + this.form.image.name.split('.').pop()
          const fileOrgName = this.form.image.name
          const dateYear = new Date(this.product.createdOn.seconds * 1000 + this.product.createdOn.nanoseconds / 1000000).getFullYear()
          const imageTargetPath = 'products/' + dateYear + '/' + this.product.varycode

          const imageObj = {
            createdOn: new Date(),
            file: newFilename,
            orgFile: fileOrgName,
            path: imageTargetPath,
            meta: metadata,
            userId: self.$store.state.auth.userProfile.uid,
            deleted: false
          }

          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(this.form.image, metadata) // Upload the file

          // We're adding images to a object to keep the history of uploaded images
          const nextImageId = self.product.image !== undefined
            ? parseInt(Object.keys(self.product.image)[Object.keys(self.product.image).length - 1]) + 1
            : 0

          // Wait for the files to be uploaded
          await this.uploadTaskPromise(uploadTask)

          // Save the data to the DB (filesCollection)
          self.uploadStatus = 2

          await fb.productsCollection.doc(self.product._id).update({
            [`image.${nextImageId}`]: imageObj
          })

          // Update imageinfo in all prospects using this product
          const batchArray = []
          batchArray.push(fb.db.batch())
          let operationCounter = 0
          let batchIndex = 0

          self.prospects.forEach((prospect) => {
            // let roomKey = null
            // let productKey = null

            if ( // Check if the prospect has rooms
              prospect.rooms !== undefined &&
              typeof prospect.rooms === 'object' &&
              prospect.rooms !== null
            ) {
              Object.keys(prospect.rooms).map((roomKey) => {
                const products = prospect.rooms[roomKey].products
                if ( // Check if the room has products
                  products !== undefined &&
                  typeof products === 'object' &&
                  products !== null
                ) {
                  Object.keys(products).map((prodKey) => {
                    const prod = products[prodKey]
                    if (
                      prod !== undefined &&
                      typeof prod === 'object' &&
                      prod !== null
                    ) {
                      if (prod.parentId === self.product._id) {
                        batchArray[batchIndex].update(fb.prospectsCollection.doc(prospect._id), {
                          [`rooms.${roomKey}.products.${prodKey}.image`]: self.product.image
                        })
                        operationCounter++

                        if (operationCounter === 499) {
                          batchArray.push(fb.db.batch())
                          batchIndex++
                          operationCounter = 0
                        }
                      }
                    }
                  })
                }
              })
            }
          })

          await batchArray.map(async batch => await batch.commit())

          self.$bvToast.toast(self.trans('projects.image_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
        } else {
          this.uploadStatus = 0
          console.log('Imagefile data not found')
        }
      },
      uploadTaskPromise (uploadTask) {
        return new Promise((resolve, reject) => {
          uploadTask.on('state_changed', function (snapshot) {
            self.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100

            switch (snapshot.state) {
              case 'paused':
                console.log('Upload is paused')
                break
              case 'running':
                console.log('Upload is running')
            }
          }, function (error) {
            switch (error.code) {
              case 'storage/unauthorized':
                console.log('Storage autorization required, access denied...')
                break
              case 'storage/canceled':
                console.log('The upload was cancelled')
                break
              case 'storage/unknown':
                console.log('An unknown error occurred during upload')
                break
            }
          }, function () {
            // Upload files and save them to the filesCollection
            resolve()
          })
        })
      },
      updateProduct () {
        const self = this
        this.submitStatus = 1

        const productData = {
          name: this.form.name,
          shorttxt: this.productDescHtml,
          longtxt: this.form.longtxt
        }

        if (this.form.price !== null && this.form.price !== undefined) productData.price = this.form.price

        fb.productsCollection.doc(this.product._id).update(productData).then(() => {
          self.$bvToast.toast('Produktdataen ble oppdatert', {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
        }).then(() => {
          console.log('Updating ALL related docs')
          const batchArray = []
          batchArray.push(fb.db.batch())
          let operationCounter = 0
          let batchIndex = 0

          self.prospects.forEach((prospect) => {
            // let roomKey = null
            // let productKey = null

            if ( // Check if the prospect has rooms
              prospect.rooms !== undefined &&
              typeof prospect.rooms === 'object' &&
              prospect.rooms !== null
            ) {
              Object.keys(prospect.rooms).map((roomKey) => {
                const products = prospect.rooms[roomKey].products
                if ( // Check if the room has products
                  products !== undefined &&
                  typeof products === 'object' &&
                  products !== null
                ) {
                  Object.keys(products).map((prodKey) => {
                    const prod = products[prodKey]
                    if (
                      prod !== undefined &&
                      typeof prod === 'object' &&
                      prod !== null
                    ) {
                      if (prod.parentId === self.product._id) {
                        batchArray[batchIndex].update(fb.prospectsCollection.doc(prospect._id), {
                          [`rooms.${roomKey}.products.${prodKey}.name`]: self.form.name,
                          [`rooms.${roomKey}.products.${prodKey}.shorttxt`]: self.productDescHtml,
                          [`rooms.${roomKey}.products.${prodKey}.longtxt`]: self.form.longtxt
                        })
                        operationCounter++

                        if (operationCounter === 499) {
                          batchArray.push(fb.db.batch())
                          batchIndex++
                          operationCounter = 0
                        }
                      }
                    }
                  })
                }
              })
            }
          })

          batchArray.forEach(async batch => await batch.commit())
        }).catch((err) => {
          self.$bvToast.toast('Kunne ikke lagre produktdata', {
            title: self.trans('global.error'),
            variant: 'danger',
            solid: true
          })
          console.log('Error:', err)
        }).finally(() => {
          this.submitStatus = 0
        })
      },
      /**
       * prospectImage ()
       * Fetches the first image from the prospect-carousel
       * @param prospect
       * @returns {string}
       */
      prospectImage (prospect) {
        let img = false
        if (
          prospect !== undefined &&
          prospect.landingpage !== undefined &&
          prospect.landingpage.blocks !== undefined &&
          Object.keys(prospect.landingpage.blocks).length > 0
        ) {
          const coverblockIndex = Object.keys(prospect.landingpage.blocks)
            .findIndex(x => prospect.landingpage.blocks[x].name === 'Carousel')

          const coverblockImages = prospect.landingpage.blocks[coverblockIndex] !== undefined &&
            prospect.landingpage.blocks[coverblockIndex].images !== undefined
            ? prospect.landingpage.blocks[coverblockIndex].images
            : null

          if (coverblockImages && Object.keys(coverblockImages).length > 0) {
            const images = []

            Object.keys(coverblockImages).filter((imageKey) => {
              if (coverblockImages[imageKey].deleted !== undefined && coverblockImages[imageKey].deleted === false) {
                const imageData = coverblockImages[imageKey]
                imageData._key = imageKey
                imageData.fullURL = process.env.VUE_APP_IMAGE_CDN_URL + '/' + imageData.path + '/' + imageData.file
                images.push(imageData)
              } // else  - image has been deleted or incorrectly saved
            })

            img = images.length > 0 ? images.sort((a, b) => { return a.order - b.order })[0].fullURL : false
          }
        }

        return img
      },
      initializeEditor () {
        const self = this
        self.editor = new Editor({
          extensions: [
            new Heading({ levels: [1, 2, 3] }),
            new Blockquote(),
            new OrderedList(),
            new BulletList(),
            new ListItem(),
            new TodoItem(),
            new TodoList(),
            new Bold(),
            new Italic(),
            new CustomLink({ openOnClick: false }),
            new Strike(),
            new Underline()
          ],
          content: this.form.shorttxt,
          onInit: () => {
            self.productDescHtml = self.form.shorttxt
          },
          onUpdate: ({ getHTML }) => {
            this.productDescHtml = getHTML()
          }
        })
      },
      showLinkMenu (attrs) {
        this.linkUrl = attrs.href
        this.linkMenuIsActive = true
        this.$nextTick(() => {
          this.$refs.linkInput.focus()
        })
      },
      hideLinkMenu () {
        this.linkUrl = null
        this.linkMenuIsActive = false
      },
      setLinkUrl (command, url) {
        command({ href: url })
        this.hideLinkMenu()
      },
      formatImagesArray (val) {
        if (val !== undefined) {
          const imagesArray = []

          Object.keys(val).map((objKey) => {
            const objData = val[objKey]

            if (objData.deleted === undefined || objData.deleted === false) {
              imagesArray.push(objData)
            }
          })

          return imagesArray.sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
              return a.order - b.order
            } else {
              return false
            }
          })
        } else {
          return []
        }
      },
      async updateImages () {
        const self = this
        this.enabled = false // Disable drag'n'drop while saving data

        const updateQuery = {}
        const globalUpdateQuery = {}

        let count = 0
        this.productImages.forEach((image) => {
          updateQuery[`images.${image.id}.order`] = count
          count++
        })

        console.log(updateQuery)

        await fb.productsCollection.doc(this.product._id).update(updateQuery).then(async () => {
          // Update ALL prospects using this product
          const batchArray = []
          batchArray.push(fb.db.batch())
          let operationCounter = 0
          let batchIndex = 0

          self.prospects.forEach((prospect) => {
            if ( // Check if the prospect has rooms
              prospect.rooms !== undefined &&
              typeof prospect.rooms === 'object' &&
              prospect.rooms !== null
            ) {
              Object.keys(prospect.rooms).forEach((roomKey) => {
                const products = prospect.rooms[roomKey].products
                if ( // Check if the room has products
                  products !== undefined &&
                  typeof products === 'object' &&
                  products !== null
                ) {
                  Object.keys(products).map((prodKey) => {
                    const prod = products[prodKey]
                    if (
                      prod !== undefined &&
                      typeof prod === 'object' &&
                      prod !== null
                    ) {
                      if (prod.parentId === self.product._id) {
                        Object.keys(updateQuery).forEach((imagePath) => {
                          globalUpdateQuery[`rooms.${roomKey}.products.${prodKey}.${imagePath}`] = updateQuery[imagePath]
                        })

                        console.log('New globalUpdateQuery:', globalUpdateQuery)
                        batchArray[batchIndex].update(fb.prospectsCollection.doc(prospect._id), globalUpdateQuery)
                        operationCounter++

                        if (operationCounter === 499) {
                          batchArray.push(fb.db.batch())
                          batchIndex++
                          operationCounter = 0
                        }
                      }
                    }
                  })
                }
              })
            }
          })

          await batchArray.map(async batch => await batch.commit())

          // Show result-msg
          self.$bvToast.toast(self.trans('prospects.landingpage.reorder_images_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
          this.enabled = true
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.reorder_images_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log(err)
          this.enabled = true
        })
      },
      addFiles (data) {
        console.log('addFiles () is running')
        console.log(data)
        const droppedFiles = data
        if (droppedFiles.length === 0) return

        ([...droppedFiles]).forEach((f) => {
          this.uploadingImages.push(f)
        })

        this.dragging = false

        this.uploadImages()
      },
      async uploadImages () {
        this.uploadDisabled = true
        const self = this

        let nextImageKey = this.product.images !== undefined && Object.keys(this.product.images).length > 0
          ? parseInt(Object.keys(this.product.images).pop()) + 1 : 0

        console.log('NexTImageId', nextImageKey)
        const updateQuery = {}

        this.uploadingImages.forEach((imageFile, index) => {
          self.nextImageKeys[index] = nextImageKey

          updateQuery[`images.${nextImageKey}`] = {
            id: nextImageKey,
            createdOn: new Date(),
            userId: self.$store.state.auth.userProfile.uid,
            order: nextImageKey,
            deleted: false
          }

          nextImageKey++
        })

        await fb.productsCollection.doc(this.product._id).update(updateQuery).catch((err) => {
          console.log('There was a error while creating the productImage DB entries, quitting...', err)
        })

        await Promise.all(this.uploadingImages.map((image, index) => this.uploadFile(image, index)))
          .then(async () => {
            console.log('Executing the update of ALL prospects using this product...')
            await self.updateAllProspectsUsingThisProductBatchArray.map(async batch => await batch.commit())
          })
          .then(() => {
            console.log('All images was uploaded and all prospects were updated...')
            self.formFiles = []
            self.imageUploadProgress = {} // Reset the uploadProgressObject
            self.uploadDisabled = false
            self.uploadingImages = []
            self.nextImageKeys = {} // Reset the tmp keyholder object

            // Reset ALL batch tasks as well
            self.updateAllProspectsUsingThisProductBatchArray = []
            self.updateAllProspectsUsingThisProductBatchIndex = 0
            self.updateAllProspectsUsingThisProductBatchCounter = 0
          }).catch((err) => {
            console.log('There was a error while uploading the images', err)
          })
      },
      uploadFile (imagefile, arrayIndex) {
        const self = this
        console.log('UploadingFile:')
        if (imagefile && this.product !== undefined) {
          console.log('ProductInfo:', this.product)

          const date = objectPath.get(this, 'product.createdOn.seconds', false)
          const path = date
            ? 'products/' + new Date(parseInt(date) * 1000).getFullYear() + '/' + this.product.varycode
            : objectPath.get(this, 'product.image.0.path', false)

          let randomTxt = ''
          const length = 16
          const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

          for (let i = 0; i < length; i++) {
            randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
          }

          // projects/[project-VARYCODE]/prospects/[prospect-VARYCODE]/landingpage/block-[blockKEY]/images/[filename].ext
          const orgFilename = imagefile.name
          const newFilename = randomTxt + '.' + imagefile.name.split('.').pop()
          const imageTargetPath = path + '/images'

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: imagefile.type
          }

          // Prepare a image
          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imagefile, metadata) // Upload the file

          return new Promise((resolve, reject) => {
            uploadTask.on('state_changed', function (snapshot) {
              Vue.set(self.imageUploadProgress, self.nextImageKeys[arrayIndex], (snapshot.bytesTransferred / snapshot.totalBytes) * 100)
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
              }
            }, function (error) {
              let err = ''
              switch (error.code) {
                case 'storage/unauthorized':
                  err = 'Storage autorization required, access denied...'
                  break
                case 'storage/canceled':
                  err = 'The upload was cancelled'
                  break
                case 'storage/unknown':
                  err = 'An unknown error occurred during upload'
                  break
              }
              reject(new Error(err))
            }, function () {
              delete self.imageUploadProgress[self.nextImageKeys[arrayIndex]]

              const imgUpdQuery = {}
              const idx = self.nextImageKeys[arrayIndex] // key.arridx
              imgUpdQuery[`images.${idx}.file`] = newFilename
              imgUpdQuery[`images.${idx}.orgFile`] = orgFilename
              imgUpdQuery[`images.${idx}.path`] = imageTargetPath
              imgUpdQuery[`images.${idx}.metadata`] = metadata

              const allImageDataForSavingInProspectImagesData = Object.assign(
                {},
                objectPath.get(self, `product.images.${idx}`),
                {
                  file: newFilename,
                  orgFile: orgFilename,
                  path: imageTargetPath,
                  metadata
                })

              fb.productsCollection.doc(self.product._id).update(imgUpdQuery).then(() => {
                // Update images-info in ALL prospects using this product O.o
                self.updateAllProspectsUsingThisProductBatchArray.push(fb.db.batch())

                self.prospects.forEach((prospect) => {
                  if ( // Check if the prospect has rooms
                    prospect.rooms !== null &&
                    prospect.rooms !== undefined &&
                    typeof prospect.rooms === 'object'
                  ) {
                    Object.keys(prospect.rooms).forEach((roomKey) => {
                      const products = prospect.rooms[roomKey].products
                      if ( // Check if the room has products
                        products !== null &&
                        products !== undefined &&
                        typeof products === 'object'
                      ) {
                        Object.keys(products).forEach((prodKey) => {
                          const prod = products[prodKey]
                          if (
                            prod !== undefined &&
                            typeof prod === 'object' &&
                            prod !== null
                          ) {
                            if (prod.parentId === self.product._id) {
                              self.updateAllProspectsUsingThisProductBatchArray[self.updateAllProspectsUsingThisProductBatchIndex].update(
                                fb.prospectsCollection.doc(prospect._id), {
                                  [`rooms.${roomKey}.products.${prodKey}.images.${idx}`]: allImageDataForSavingInProspectImagesData
                                }
                              )
                              self.updateAllProspectsUsingThisProductBatchCounter++

                              if (self.updateAllProspectsUsingThisProductBatchCounter === 499) {
                                self.updateAllProspectsUsingThisProductBatchArray.push(fb.db.batch())
                                self.updateAllProspectsUsingThisProductBatchIndex++
                                self.updateAllProspectsUsingThisProductBatchCounter = 0
                              }
                            }
                          }
                        })
                      }
                    })
                  }
                })

                self.$bvToast.toast(self.trans('prospects.landingpage.carousel_image_upload_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
                resolve()
              }).catch((err) => {
                self.$bvToast.toast(self.trans('prospects.landingpage.carousel_image_upload_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
                console.log('There was an error while saving the imagedata to the DB, quitting...')
                console.log(err)
                // eslint-disable-next-line prefer-promise-reject-errors
                reject()
              })
            })
          })
        } else {
          console.log('Error: Missing imagefile-, prospect- or projectdata')
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject()
        }
      }
    }
  }
</script>

<!--suppress CssInvalidPropertyValue -->
<style lang="scss" scoped>
  .carouselwrp {
    padding: 16px;

    .draggableimages {
      display: flex;
      flex-wrap: wrap;

      &.disabled {
        background-color: #ffc373;
      }
    }

    .uploadField {
      position: relative;
      background-color: #f8f8f8;
      background-image: linear-gradient(45deg, #ededed 25%, #f8f8f8 25%, #f8f8f8 50%, #ededed 50%, #ededed 75%, #f8f8f8 75%, #f8f8f8 100%);
      background-size: 56.57px 56.57px;
      text-align: center;
      border: 1px dashed #eeeeee;
      border-radius: 6px;
      color: #c3c3c3;

      &.dragactive, &:hover {
        background-color: #67C18D;
        background-image: linear-gradient(45deg, #46ab72 25%, #67c18d 25%, #67c18d 50%, #46ab72 50%, #46ab72 75%, #67c18d 75%, #67c18d 100%);
        background-size: 56.57px 56.57px;
        border: 1px dashed #67C18D;
        color: white;
      }

      i {
        margin-top: 20px;
        font-size: 60px;
      }

      p {
        line-height: 90px;
        margin: 0;
      }

      /deep/ .b-form-file {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        bottom:0;
        width: 100%;
        height: auto;

        .custom-file-input {
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .modal-header-wrp {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 26px;

    h4 {
      margin-bottom: 0;
    }
  }

  #product_image_group {
    width: 300px;
  }

  .vary-image-container {
    display: flex;
    background-color: #fcfcfc;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px solid #DFE4EB;
    border-radius: 4px;
    width: 565px;
    height: 565px;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    &.noimg {
      padding-top: 300px / 300px * 100%;
    }

    .text {
      margin-top: -(300px / 300px * 100%);
      color: #B5BCC6;
      text-align: center;

      i {
        margin-bottom: 20px;
      }

      span {
        display: block;
      }
    }
  }

  .vary-image-progress {
    transition: opacity .5s;
    width: 100%;
    margin-bottom: 10px;
  }

  .menububble {
    position: absolute;
    display: -webkit-box;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s
  }

  .menububble.is-active {
    opacity: 1;
    visibility: visible
  }

  .menububble__button {
    display: -webkit-inline-box;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #fff;
    padding: .2rem .5rem;
    margin-right: .2rem;
    border-radius: 3px;
    cursor: pointer
  }

  .menububble__button:last-child {
    margin-right: 0
  }

  .menububble__button:hover {
    background-color: hsla(0,0%,100%,.1)
  }

  .menububble__button.is-active {
    background-color: hsla(0,0%,100%,.2)
  }

  .menububble__form {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center
  }

  .symbol-50 {
    width: 50px;
    height: 50px;
  }

  @media (min-width: 992px) {
    .symbol-lg-120 {
      width: 120px;
      height: 120px;
    }
  }

  .prodlist {
    > div {
      border-top: 1px solid #eaeaea;
    }

    &:first-child {
      > div {
        border-top: none;
        margin-top: -20px;
      }
    }
  }
</style>
